import React from 'react';
import { string } from 'prop-types';


const ICON_CAMERA = 'IconCamera';
const ICON_UPLOAD = 'IconUpload';
const ICON_CLOSE = "IconClose";
const ICON_INFO = 'infoIcon';
export const BACK_ICON = 'backIcon';
export const NEXT_ICON = 'nextIcon';
const FILTER_ICON = 'filterIcon';

const IconCollection = props => {
    const { className, rootClassName, name } = props;


    switch (name) {
        case ICON_CAMERA:
            return (
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_63_4643)">
                        <path d="M23.7582 6.47773H18.502L17.7388 4.51926C17.6344 4.24862 17.4507 4.01583 17.2117 3.85138C16.9727 3.68693 16.6897 3.59849 16.3996 3.59763H10.9562C10.6649 3.59703 10.3802 3.6848 10.1398 3.84935C9.89944 4.01389 9.7146 4.24748 9.60973 4.51926L8.8537 6.47773H3.59752C3.2156 6.47773 2.84931 6.62945 2.57925 6.89951C2.30919 7.16957 2.15747 7.53585 2.15747 7.91778V22.3183C2.15747 22.7002 2.30919 23.0665 2.57925 23.3366C2.84931 23.6066 3.2156 23.7583 3.59752 23.7583H23.7582C24.1402 23.7583 24.5064 23.6066 24.7765 23.3366C25.0466 23.0665 25.1983 22.7002 25.1983 22.3183V7.91778C25.1983 7.53585 25.0466 7.16957 24.7765 6.89951C24.5064 6.62945 24.1402 6.47773 23.7582 6.47773ZM5.15998 10.5315C5.00721 10.5315 4.86069 10.4708 4.75267 10.3628C4.64464 10.2547 4.58396 10.1082 4.58396 9.95545C4.58396 9.80268 4.64464 9.65617 4.75267 9.54814C4.86069 9.44012 5.00721 9.37943 5.15998 9.37943H6.88804C7.04081 9.37943 7.18732 9.44012 7.29534 9.54814C7.40337 9.65617 7.46406 9.80268 7.46406 9.95545C7.46406 10.1082 7.40337 10.2547 7.29534 10.3628C7.18732 10.4708 7.04081 10.5315 6.88804 10.5315H5.15998ZM13.6779 20.8782C12.3962 20.8782 11.1433 20.4982 10.0777 19.7861C9.01198 19.0741 8.1814 18.062 7.69092 16.8779C7.20045 15.6938 7.07212 14.3908 7.32216 13.1338C7.5722 11.8767 8.18938 10.7221 9.09566 9.81579C10.0019 8.90952 11.1566 8.29234 12.4136 8.0423C13.6707 7.79225 14.9736 7.92058 16.1577 8.41106C17.3419 8.90153 18.3539 9.73212 19.066 10.7978C19.778 11.8635 20.1581 13.1163 20.1581 14.398C20.1581 16.1167 19.4754 17.7649 18.2601 18.9802C17.0448 20.1955 15.3965 20.8782 13.6779 20.8782Z" fill="#4A4A4A" />
                        <path d="M8.71692 14.4412C8.71837 15.4127 8.99778 16.3635 9.52215 17.1813C10.0465 17.9991 10.794 18.6498 11.6762 19.0566L12.461 17.8037C11.983 17.6003 11.5546 17.2958 11.2054 16.9111C10.8562 16.5265 10.5943 16.0708 10.4378 15.5754C10.2813 15.08 10.2339 14.5566 10.2989 14.0411C10.3638 13.5257 10.5395 13.0304 10.814 12.5892C11.0884 12.1481 11.4551 11.7716 11.8888 11.4856C12.3226 11.1996 12.8131 11.0108 13.3266 10.9323C13.8402 10.8538 14.3647 10.8873 14.864 11.0307C15.3634 11.174 15.8258 11.4237 16.2196 11.7627L17.0044 10.4955C16.2587 9.88999 15.3566 9.50821 14.4028 9.39435C13.449 9.2805 12.4824 9.43924 11.6151 9.85217C10.7478 10.2651 10.0152 10.9153 9.50229 11.7275C8.98936 12.5397 8.71705 13.4806 8.71692 14.4412Z" fill="#4A4A4A" />
                    </g>
                    <defs>
                        <clipPath id="clip0_63_4643">
                            <rect width="25.9209" height="25.9209" fill="white" transform="translate(0.717407 0.717529)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case ICON_UPLOAD:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.965 16.7917H7.5C6.50544 16.7917 5.55161 16.3834 4.84835 15.6567C4.14509 14.93 3.75 13.9444 3.75 12.9167C3.75 11.889 4.14509 10.9033 4.84835 10.1766C5.55161 9.44993 6.50544 9.04167 7.5 9.04167C7.85 9.04167 8.025 9.04167 8.14625 9.01583C8.51875 8.93833 8.69625 8.82079 8.91625 8.50046C8.98875 8.39713 9.09125 8.16204 9.295 7.69188C9.78807 6.55448 10.589 5.58876 11.6013 4.91117C12.6136 4.23357 13.7939 3.87304 15 3.87304C16.2061 3.87304 17.3864 4.23357 18.3987 4.91117C19.411 5.58876 20.2119 6.55448 20.705 7.69188C20.9087 8.16204 21.0113 8.39583 21.0838 8.50046C21.305 8.82079 21.4812 8.93963 21.8538 9.01713C21.9762 9.04167 22.1513 9.04167 22.5 9.04167C23.4946 9.04167 24.4484 9.44993 25.1517 10.1766C25.8549 10.9033 26.25 11.889 26.25 12.9167C26.25 13.9444 25.8549 14.93 25.1517 15.6567C24.4484 16.3834 23.4946 16.7917 22.5 16.7917H21.035L16.7675 12.3819L15 10.5555L13.2325 12.3819L8.965 16.7917Z" fill="#4B5563" />
                    <path d="M15 15.5L14.1162 14.5868L15 13.6736L15.8837 14.5868L15 15.5ZM16.25 27.125C16.25 27.4676 16.1183 27.7961 15.8838 28.0383C15.6494 28.2806 15.3315 28.4167 15 28.4167C14.6684 28.4167 14.3505 28.2806 14.1161 28.0383C13.8817 27.7961 13.75 27.4676 13.75 27.125H16.25ZM9.11621 19.7535L14.1162 14.5868L15.8837 16.4132L10.8837 21.5799L9.11621 19.7535ZM15.8837 14.5868L20.8837 19.7535L19.1162 21.5799L14.1162 16.4132L15.8837 14.5868ZM16.25 15.5V27.125H13.75V15.5H16.25Z" fill="#4B5563" />
                </svg>
            );
        case ICON_CLOSE:
            return (
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.75 5.25L5.25 15.75M5.25 5.25L15.75 15.75" stroke="#5B4C23" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            );
            case ICON_INFO:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clip-path="url(#clip0_1780_5348)">
                      <path d="M9.5 1.58331C13.8724 1.58331 17.4167 5.1276 17.4167 9.49998C17.4167 13.8724 13.8724 17.4166 9.5 17.4166C5.12763 17.4166 1.58334 13.8724 1.58334 9.49998C1.58334 5.1276 5.12763 1.58331 9.5 1.58331ZM9.49209 7.91665H8.70834C8.50656 7.91687 8.31248 7.99413 8.16575 8.13265C8.01903 8.27117 7.93073 8.46048 7.91891 8.66192C7.90708 8.86335 7.97262 9.0617 8.10213 9.21643C8.23164 9.37116 8.41534 9.47061 8.61571 9.49444L8.70834 9.49998V13.4504C8.70834 13.8621 9.02025 14.2025 9.42084 14.2452L9.50792 14.25H9.89584C10.0623 14.25 10.2246 14.1975 10.3595 14.1C10.4945 14.0025 10.5953 13.8649 10.6475 13.7068C10.6998 13.5487 10.7009 13.3782 10.6506 13.2194C10.6004 13.0607 10.5014 12.9218 10.3677 12.8226L10.2917 12.7727V8.71623C10.2917 8.30456 9.97975 7.96415 9.57917 7.9214L9.49209 7.91665ZM9.5 5.54165C9.29004 5.54165 9.08868 5.62505 8.94021 5.77352C8.79174 5.92199 8.70834 6.12335 8.70834 6.33331C8.70834 6.54328 8.79174 6.74464 8.94021 6.89311C9.08868 7.04157 9.29004 7.12498 9.5 7.12498C9.70996 7.12498 9.91133 7.04157 10.0598 6.89311C10.2083 6.74464 10.2917 6.54328 10.2917 6.33331C10.2917 6.12335 10.2083 5.92199 10.0598 5.77352C9.91133 5.62505 9.70996 5.54165 9.5 5.54165Z" fill="#A4C3E6"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1780_5348">
                        <rect width="19" height="19" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
    
                )
                case BACK_ICON:
                    return (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.51176 10L13.4766 5.03906C13.8438 4.67188 13.8438 4.07813 13.4766 3.71485C13.1094 3.34766 12.5157 3.35156 12.1485 3.71485L6.52348 9.33594C6.16801 9.69141 6.1602 10.2617 6.49614 10.6289L12.1446 16.2891C12.3282 16.4727 12.5704 16.5625 12.8086 16.5625C13.0469 16.5625 13.2891 16.4727 13.4727 16.2891C13.8399 15.9219 13.8399 15.3281 13.4727 14.9648L8.51176 10Z"
                          fill="black"
                        />
                      </svg>
                    );
                    case NEXT_ICON:
                        return (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.4883 10L6.52344 5.03906C6.15625 4.67188 6.15625 4.07812 6.52344 3.71484C6.89062 3.35156 7.48438 3.35156 7.85156 3.71484L13.4766 9.33594C13.832 9.69141 13.8398 10.2617 13.5039 10.6289L7.85547 16.2891C7.67188 16.4727 7.42969 16.5625 7.19141 16.5625C6.95313 16.5625 6.71094 16.4727 6.52734 16.2891C6.16016 15.9219 6.16016 15.3281 6.52734 14.9648L11.4883 10Z"
                              fill="black"
                            />
                          </svg>
                        );
                        case FILTER_ICON:
                            return (
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                className="text-2xl"
                                height="24px"
                                width="24px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M472 168H40a24 24 0 010-48h432a24 24 0 010 48zm-80 112H120a24 24 0 010-48h272a24 24 0 010 48zm-96 112h-80a24 24 0 010-48h80a24 24 0 010 48z"></path>
                              </svg>
                            );
        default:
            return (
                <>
                </>
            );
    }
};

IconCollection.defaultProps = {
    className: null,
    rootClassName: null,
    brand: 'default',
};

IconCollection.propTypes = {
    className: string,
    rootClassName: string,
    brand: string,
};

export default IconCollection;
